import React, {useEffect, useState} from 'react'
import s from './AccountTopUp.module.css'
import {Popover} from "antd";
import infoIcon from "../../../../assets/newImg/personalAccount/info.svg"
import CustomDropdownForAccounts from "../../../../components/ui/CustomDropdownForAccounts/CustomDropdownForAccounts";
import {useAppDispatch} from "../../../../hooks/redux";
import {Formik, useFormikContext} from "formik";
import {setFilters, setFiltersTemplates} from "../../../../store/reducers/TransactionsSlice/TransactionsSlice";
import {useSelector} from "react-redux";
import {selectAccountsByFilter, selectTopUpByAccount} from "../../../../store/reducers/TransactionsSlice/selector";
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import PersonalIban from "./PersonalIban/PersonalIban";
import CorrespondentAccount from "./CorrespondentAccount/CorrespondentAccount";
import {brandConfig} from '../../../../config/config';

const AutoSubmitComponent = () => {

    const {values, submitForm} = useFormikContext();

    useEffect(() => {
        submitForm()
    }, [values, submitForm])

    return null
}

export const AccountTopUp = () => {

    const accountsList = useSelector(selectAccountsByFilter)
    const accountTopUpList = useSelector(selectTopUpByAccount)
    const dispatch = useAppDispatch()
    const [tabIndex, setTabIndex] = useState(0);

    useEffect(() => {
        const clearFilters = () => {
            dispatch(setFiltersTemplates(
                {
                    accountNumber: '',
                    period: null,
                    from: '',
                    to: '',
                    purpose: '',
                    recipient: '',
                    amount: '',
                    incoming: false,
                    outgoing: false,
                    currency: '',
                    selectedType: '',
                    templateName: '',
                    paymentRegion: ''
                }
            ))
        }

        accountTopUpList?.iber?.forEach((item: any) => {
            if (item.isMain) {
                setTabIndex(1);
            }
        })

        return clearFilters()
    }, [accountTopUpList, dispatch])

    return (
        <div className={s.accountTopUpWrapper}>
            <div className={s.infoWrapper}>
                <p>
                    Account top-up executed not in accordance with our instructions is charged with an additional
                    crediting/refunding fee, depending on the particular bank.
                </p>
                <p>You can add funds to your {brandConfig.companyNameSimple} account by a standard transfer from you bank account. There
                    is <b>no fee&nbsp;</b>
                    for topping up your {brandConfig.companyNameSimple} account unless the incoming payment is made via the TARGET2 system. In
                    this case, a crediting fee applies: for amounts up to 9,999.99 USD, the fee is <b>3.00 USD</b>, for
                    10,000.00
                    USD or more, the fee is <b>4.00 USD.</b></p>
            </div>
            <Formik initialValues={{
                accountNumber: ''
            }}
                    onSubmit={(values) => {
                        dispatch(setFilters({accountNumber: values.accountNumber}))
                    }}
            >
                {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleSubmit
                  }) => (
                    <form onSubmit={handleSubmit}>
                        <div className={s.detailsWrapper}>
                            <p className={s.detailsTitle}>Details for the Bank Transfer</p>
                            <div>
                                <p className={s.labelText}>Payer account <Popover
                                    content={"Your internal account number."}
                                    color={'#EEEFEF'}
                                    placement={"bottom"}
                                    overlayInnerStyle={{
                                        fontFamily: 'Inter',
                                        padding: '13px 16px',
                                        backgroundColor: '#EEEFEF',
                                        boxShadow: 'none'
                                    }}
                                >
                                    <img src={infoIcon} alt=""/>
                                </Popover></p>
                                <CustomDropdownForAccounts
                                    items={accountsList}
                                    id='accountNumber'
                                    name='accountNumber'
                                    placeholder={"Please select the account"}
                                />
                            </div>

                            {
                                (accountTopUpList?.virtual?.length > 0 || accountTopUpList?.iber?.length > 0) &&
                                <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
                                    <TabList className={s.tabstitle_block}>
                                        {accountTopUpList?.virtual?.length > 0 &&
                                            <Tab selectedClassName={s.active_tab} className={s.tabtitle}>Personal
                                                IBAN</Tab>}
                                        {accountTopUpList?.iber?.length > 0 &&
                                            <Tab selectedClassName={s.active_tab} className={s.tabtitle}>{brandConfig.companyNameSimple}
                                                Correspondent Account</Tab>}
                                    </TabList>
                                    {
                                        accountTopUpList?.virtual?.length > 0 &&
                                        <TabPanel>
                                            <PersonalIban list={accountTopUpList?.virtual}/>
                                        </TabPanel>
                                    }
                                    {
                                        accountTopUpList?.iber?.length > 0 &&
                                        <TabPanel>
                                            <CorrespondentAccount list={accountTopUpList?.iber}/>
                                        </TabPanel>
                                    }
                                </Tabs>
                            }
                        </div>

                        <AutoSubmitComponent/>

                    </form>
                )}
            </Formik>
        </div>
    )
}
