import React, {useState} from 'react';
import KycBusinessStepOne from "./KycBusinessStepOne/KycBusinessStepOne";
import KycBusinessStepTwo from "./KycBusinessStepTwo/KycBusinessStepTwo";
import EditKyc from "./EditKyc/EditKyc";

const KycBusiness = () => {

    const [current, setCurrent] = useState(0);

    const steps = [
        {
            content: <KycBusinessStepOne setCurrent={setCurrent}
                                         current={current}
            />
        },
        {
            content: <KycBusinessStepTwo setCurrent={setCurrent}
                                         current={current}
            />
        },
        {
            content: <EditKyc setCurrent={setCurrent}
                              current={current}
            />
        },
    ];

    return (
        <div>{steps[current].content}</div>
    );
};

export default KycBusiness;
