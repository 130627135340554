import React, {Dispatch, SetStateAction, useRef, useState} from 'react';
import s from './KycPersonalStepTwo.module.css'
import {Field, Formik, Form} from "formik";
import MyInput from "../../../../../../components/ui/MyInput/MyInput";
import info from '../../../../../../assets/newImg/personalAccount/information.svg'
import {Checkbox, DatePicker, Popover} from "antd";
import MyBtn from "../../../../../../components/ui/MyBtn/MyBtn";
import * as yup from "yup";
import CustomCheckbox from "../../../../../../components/ui/customCheckbox/CustomCheckbox";
import classnames from "classnames";
import passportSpread from "../../../../../../assets/newImg/personalAccount/passport_spread.png"
import {ReactComponent as Plus} from "../../../../../../assets/newImg/personalAccount/plus.svg"
import {ReactComponent as Back} from "../../../../../../assets/newImg/personalAccount/back.svg"
import selfi from "../../../../../../assets/newImg/personalAccount/selfi.png"
import proof from "../../../../../../assets/newImg/personalAccount/proof.png"
import idCard from "../../../../../../assets/newImg/personalAccount/idCard.png"
import attachedDoc from "../../../../../../assets/newImg/personalAccount/attached_document.svg"
import delete_document from "../../../../../../assets/newImg/personalAccount/delete_document.svg"
import {useAppDispatch, useAppSelector} from "../../../../../../hooks/redux";
import {sendPersonalKycThunk, setTokenState} from "../../../../../../store/reducers/ActionCreators";
import CustomModal from "../../../../../../components/ui/CustomModal/CustomModal";
import {motion} from 'framer-motion';
import {setDisabled} from "../../../../../../store/reducers/UserSlice";
import CustomDropdown from '../../../../../../components/ui/CustomDropdown/CustomDropdown';
import Icon from "@ant-design/icons";
import TransparentButton from "../../../../../../components/ui/TransparentButton/TransparentButton";
import {countries, phonecodes} from "../../../../../../Constants/StaticData";
import {IPhoneNumber} from "../../../../../../types/interfaces";
import SelectWithSearch from "../../../../../../components/ui/SelectWithSearch/SelectWithSearch";
import {myDate} from "../../../../../../helpers/CalculateDate";
import dayjs from "dayjs";
import {IdentityCardTypeHelper} from "../../../../../../helpers/KycFormHelper";
import {toBase64} from "../../../../../../helpers/toBase64";
import {brandConfig} from '../../../../../../config/config';

type PropsType = {
    current: number
    setCurrent: Dispatch<SetStateAction<number>>
}

const validationSchema = yup.object({
    phoneNumber: yup.string()
        .required('Required field'),
    firstName: yup.string()
        .max(60, 'max 60')
        .required('Required field'),
    lastName: yup.string()
        .required('Required field')
        .max(60, 'max 60'),
    citizenshipName: yup.string().required('Required field').max(90, 'max 90'),
    countryName: yup.string().required('Required field').max(90, 'max 90'),
    city: yup.string().required('Required field').max(90, 'max 90'),
    address: yup.string().required('Required field').max(90, 'max 90'),
    identityCardType: yup.string().required('Required field').max(60, 'max 60'),
    identityCardId: yup.string().required('Required field').max(60, 'max 60'),
    postalCode: yup.string().required('Required field'),
    taxNumber: yup.string().required('Required field').max(60, 'max 60'),
    otherSourcesOfWealth: yup.string().test({
        message: 'Required field',
        test: function (value) {
            const {isOtherSourcesOfWealth} = this.parent;
            if (isOtherSourcesOfWealth) return value !== undefined && value !== null && value !== '';
            return true
        },
    }),
    currencies: yup.array().min(1, "At least one account is required"),
    sourcesOfWealth: yup.array().test({
        message: 'At least one source is required',
        test: function (value) {
            const {isOtherSourcesOfWealth} = this.parent;
            if (!isOtherSourcesOfWealth) return value !== undefined && value !== null && value.length > 0;
            return true
        },
    }),
})

const KycPersonalStepTwo = (props: PropsType) => {

    const token: string = useAppSelector(state => state.userReducer.user.userToken)
    const dispatch = useAppDispatch()
    const firstNameRef = useRef<any>(null!)

    const [documents, setDocuments] = useState<any>(
        {
            passportSpread: '',
            selfie: '',
            proofOfAddress: '',
            backOfIdCard: '',
            idCard: '',
        }
    )

    const [docsError, setDocsError] = useState(false)
    const [isPep, setIsPep] = useState(false)
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("Something went wrong. Please try again later.");
    const initPhoneNumbers = useAppSelector(state => state.userReducer.user.phoneList)
    const [date, setDate] = useState({
        from: (myDate.getFullYear() + "-" + (myDate.getMonth() + 1 < 10 ? ("0" + (myDate.getMonth() + 1)).slice(-2) : myDate.getMonth() + 1) + "-" + (myDate.getDate() < 10 ? ("0" + myDate.getDate()).slice(-2) : myDate.getDate())),
    })

    const getPhoneNumbersArr = (): string[] => {
        const phonesArr: string[] | null = [];
        initPhoneNumbers?.forEach((item: IPhoneNumber) => {
            if (item.phoneNumber != null) {
                phonesArr.push(item.phoneNumber);
            }
        });
        return phonesArr;
    }

    const getCountryCode = (countryName: string) => {
        const country = phonecodes.find((entry) => entry.na === countryName);
        return country?.co;
    };

    const closeAndRedirect = () => {
        setIsModalOpen(false)
        props.setCurrent(0)
    }

    // const myHandleSubmit = (submit: any, errors: any) => {
    //     console.log(firstNameRef)
    //     firstNameRef.current.children[1].children[0].focus()
    //     submit()
    // }

    const dateStyle = {
        fontFamily: 'Inter',
        fontWeight: 600,
        fontSize: 14,
        lineHeight: '18px',
        color: '#2A2E37',
        padding: '17px 16px',
        height: 55,
        width: "100%",
        border: '1.5px solid #EEEFEF',
        borderRadius: 8
    }

    const dateFormat = 'YYYY-MM-DD';

    const idCardsTypes = ["Passport", "National ID", "Driver’s License"];

    const sourcesOfWealthHandlerChange = (e: any, meta: any, form: any, name: string) => {
        form.setFieldValue("otherSourcesOfWealth", '');
        form.setFieldValue("isOtherSourcesOfWealth", false);
        const {checked} = e.target;
        if (checked) {
            form.setFieldValue("sourcesOfWealth", [...meta.value.sourcesOfWealth, name]);
        } else {
            const filteredArr = meta.value.sourcesOfWealth.filter((i: string) => i !== name)
            form.setFieldValue("sourcesOfWealth", filteredArr);
        }
    }

    return (
        <motion.div
            initial={{opacity: 0, scale: 1}}
            animate={{opacity: 1, scale: 1}}
            transition={{duration: 0.5}}
        >
            <div className={s.wrapper}>

                <CustomModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen}>
                    <div className={s.flex}>
                        <div className={s.modal_title}>
                            Success!
                        </div>
                        <div className={s.modal_subtitle}>
                            Your details have been sent for review.
                        </div>
                        <div className={s.button_block}>
                            <MyBtn title={"Ok"} onClick={() => closeAndRedirect()}/>
                        </div>
                    </div>
                </CustomModal>
                <CustomModal type="error" isModalOpen={isErrorModalOpen} setIsModalOpen={setIsErrorModalOpen}>
                    <div className={s.flex}>
                        <div className={s.modal_title}>
                            Oops!
                        </div>
                        <div className={s.modal_subtitle}>
                            {errorMessage}
                        </div>
                        <div className={s.button_block}>
                            <MyBtn buttonType="error" title={"Ok"} onClick={() => setIsErrorModalOpen(false)}/>
                        </div>
                    </div>
                </CustomModal>

                <div className={s.page_title}>
                    KYC for Personal Accounts
                </div>

                <Formik
                    initialValues={{
                        firstName: '',
                        lastName: '',
                        countryName: '',
                        city: '',
                        state: '',
                        postalCode: '',
                        identityCardType: '',
                        identityCardId: '',
                        address: '',
                        phoneNumber: '',
                        taxNumber: '',
                        citizenshipName: '',
                        sourcesOfWealth: [],
                        isOtherSourcesOfWealth: false,
                        otherSourcesOfWealth: '',
                        pep: false,
                        currencies: [],
                        files: {
                            passportSpread: '',
                            selfie: '',
                            proofOfAddress: '',
                        }
                    }}
                    validationSchema={validationSchema}
                    onSubmit={async (values, formikHelpers) => {

                        const tempData = {
                            ...values,
                            phoneNumber: values.phoneNumber,
                            dateOfBirth: date.from,
                            country: getCountryCode(values.countryName),
                            citizenship: getCountryCode(values.citizenshipName),
                            pep: isPep,
                            files: {
                                passportSpread: '',
                                selfie: '',
                                proofOfAddress: '',
                            }
                        }

                        if (tempData.isOtherSourcesOfWealth) {
                            tempData.sourcesOfWealth = []
                            // @ts-ignore
                            tempData.sourcesOfWealth.push(tempData.otherSourcesOfWealth)
                        }

                        // @ts-ignore
                        delete tempData.otherSourcesOfWealth
                        // @ts-ignore
                        delete tempData.isOtherSourcesOfWealth

                        if (documents.passportSpread === '' ||
                            documents.selfie === '' ||
                            documents.proofOfAddress === ''
                        ) {
                            setDocsError(true)
                            return
                        }

                        tempData.files.passportSpread = await toBase64(documents.passportSpread) as string
                        tempData.files.proofOfAddress = await toBase64(documents.proofOfAddress) as string
                        tempData.files.selfie = await toBase64(documents.selfie) as string

                        if (documents.idCard !== '') {
                            // @ts-ignore
                            tempData.files.idCard = await toBase64(documents.idCard) as string
                        }

                        if (documents.backOfIdCard !== '') {
                            // @ts-ignore
                            tempData.files.backOfIdCard = await toBase64(documents.backOfIdCard) as string
                        }

                        dispatch(sendPersonalKycThunk(token, tempData))
                            .then((res) => {
                                if (res.data === "Created") {
                                    setIsModalOpen(true)
                                } else {
                                    setIsErrorModalOpen(true)
                                }
                            })
                            .catch((error) => {
                                console.log(error)
                                if (error.response.data === "Invalid authorization data") {
                                    dispatch(setTokenState(false))
                                    return
                                }

                                if (error.response.data === "Too many phones. Max count is 5") {
                                    setErrorMessage(error.response.data)
                                    setIsErrorModalOpen(true)
                                    return
                                }

                                if (error.response.data === "Phone already exist") {
                                    setErrorMessage("This phone number already exists. Please try another phone number.")
                                    setIsErrorModalOpen(true)
                                    return
                                }

                                if (error.code === "ERR_NETWORK" && !error.response) {
                                    setErrorMessage("Maximum files size is 90 MB")
                                    setIsErrorModalOpen(true)
                                } else {
                                    setErrorMessage("Something went wrong. Please try again later.")
                                    setIsErrorModalOpen(true)
                                }
                            })
                            .finally(() => {
                                dispatch(setDisabled(false))
                            })
                    }}
                >
                    {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit
                      }) => (
                        <Form onSubmit={handleSubmit}>
                            <div className={s.row}>
                                <div className={s.input_block} ref={firstNameRef}>
                                    <div className={s.input_label}>
                                        * First name
                                        <Popover content={"Enter your First Name as in the ID document."}
                                                 color={'#EEEFEF'}
                                                 overlayInnerStyle={{
                                                     fontFamily: 'Inter',
                                                     width: '280px',
                                                     padding: '13px 16px'
                                                 }}
                                        >
                                            <img src={info} alt=""/>
                                        </Popover>
                                    </div>
                                    <MyInput id='firstName'
                                             name='firstName'
                                             onChange={handleChange}
                                             value={values.firstName}
                                             isError={errors.firstName}
                                             touched={touched.firstName}
                                    />
                                    {errors.firstName && touched.firstName &&
                                        <div className={s.error_message}>{errors.firstName}</div>}
                                </div>

                                <div className={s.input_block}>
                                    <div className={s.input_label}>
                                        * Last name
                                        <Popover content={"Enter your Last Name as in the ID document."}
                                                 color={'#EEEFEF'}
                                                 overlayInnerStyle={{
                                                     fontFamily: 'Inter',
                                                     width: '280px',
                                                     padding: '13px 16px'
                                                 }}
                                        >
                                            <img src={info} alt=""/>
                                        </Popover>
                                    </div>
                                    <MyInput id='lastName'
                                             name='lastName'
                                             onChange={handleChange}
                                             value={values.lastName}
                                             isError={errors.lastName}
                                             touched={touched.lastName}
                                    />
                                    {errors.lastName && touched.lastName &&
                                        <div className={s.error_message}>{errors.lastName}</div>}
                                </div>
                            </div>

                            <div className={s.row}>
                                <div className={s.input_block}>
                                    <div className={s.input_label}>
                                        * Country
                                    </div>
                                    <SelectWithSearch
                                        id="countryName"
                                        name="countryName"
                                        isError={errors.countryName}
                                        items={countries}
                                        touched={touched.countryName}
                                    />
                                    {errors.countryName && touched.countryName &&
                                        <div className={s.error_message}>{errors.countryName}</div>}
                                </div>

                                <div className={s.input_block}>
                                    <div className={s.input_label}>
                                        * Phone number
                                    </div>
                                    <CustomDropdown
                                        items={getPhoneNumbersArr()}
                                        placeholder={"Choose your phone number"}
                                        id="phoneNumber"
                                        name="phoneNumber"
                                        isError={errors.phoneNumber}
                                        touched={touched.phoneNumber}
                                    />
                                    {errors.phoneNumber && touched.phoneNumber &&
                                        <div className={s.error_message}>{errors.phoneNumber}</div>}
                                </div>
                            </div>

                            <div className={s.row}>
                                <div className={s.input_block}>
                                    <div className={s.input_label}>
                                        * city
                                    </div>
                                    <MyInput id='city'
                                             name='city'
                                             onChange={handleChange}
                                             value={values.city}
                                             isError={errors.city}
                                             touched={touched.city}
                                    />
                                    {errors.city && touched.city &&
                                        <div className={s.error_message}>{errors.city}</div>}
                                </div>

                                <div className={s.input_block}>
                                    <div className={s.input_label}>
                                        state
                                    </div>
                                    <MyInput id='state'
                                             name='state'
                                             onChange={handleChange}
                                             value={values.state}
                                             isError={errors.state}
                                             touched={touched.state}
                                    />
                                    {errors.state && touched.state &&
                                        <div className={s.error_message}>{errors.state}</div>}
                                </div>
                            </div>

                            <div className={s.row}>
                                <div className={s.input_block}>
                                    <div className={s.input_label}>
                                        * Address
                                    </div>
                                    <MyInput id='address'
                                             name='address'
                                             onChange={handleChange}
                                             value={values.address}
                                             isError={errors.address}
                                             touched={touched.address}
                                    />
                                    {errors.address && touched.address &&
                                        <div className={s.error_message}>{errors.address}</div>}
                                </div>

                                <div className={s.input_block}>
                                    <div className={s.input_label}>
                                        * Postal code
                                    </div>
                                    <MyInput id='postalCode'
                                             name='postalCode'
                                             onChange={handleChange}
                                             value={values.postalCode}
                                             isError={errors.postalCode}
                                             touched={touched.postalCode}
                                    />
                                    {errors.postalCode && touched.postalCode &&
                                        <div className={s.error_message}>{errors.postalCode}</div>}
                                </div>
                            </div>

                            <div className={s.row}>
                                <div className={s.input_block}>
                                    <div className={s.input_label}>
                                        * Identity card type
                                    </div>
                                    <CustomDropdown
                                        items={idCardsTypes}
                                        id="identityCardType"
                                        name="identityCardType"
                                        isError={errors.identityCardType}
                                        touched={touched.identityCardType}
                                        disable={values.countryName !== "Canada"}
                                    />
                                    {errors.identityCardType && touched.identityCardType &&
                                        <div className={s.error_message}>{errors.identityCardType}</div>}
                                </div>

                                <div className={s.input_block}>
                                    <div className={s.input_label}>
                                        * Identity card id
                                    </div>
                                    <MyInput id='identityCardId'
                                             name='identityCardId'
                                             onChange={handleChange}
                                             value={values.identityCardId}
                                             isError={errors.identityCardId}
                                             touched={touched.identityCardId}
                                    />
                                    {errors.identityCardId && touched.identityCardId &&
                                        <div className={s.error_message}>{errors.identityCardId}</div>}
                                </div>
                            </div>

                            <div className={s.row}>
                                <div className={s.input_block}>
                                    <div className={s.input_label}>
                                        * Date of Birth
                                    </div>
                                    <DatePicker suffixIcon={null} style={dateStyle}
                                                defaultValue={dayjs(new Date(), dateFormat)}
                                                value={dayjs(date.from)}
                                                onChange={(_, dateString) => {
                                                    if (!dateString) {
                                                        setDate({...date, from: new Date().toDateString()})
                                                    } else {
                                                        setDate({...date, from: dateString})
                                                    }
                                                }}
                                                format={dateFormat}/>
                                </div>

                                <div className={s.input_block}>
                                    <div className={s.input_label}>
                                        * Tax Number
                                    </div>
                                    <MyInput id='taxNumber'
                                             name='taxNumber'
                                             onChange={handleChange}
                                             value={values.taxNumber}
                                             isError={errors.taxNumber}
                                             touched={touched.taxNumber}
                                    />
                                    {errors.taxNumber &&
                                        <div className={s.error_message}>{errors.taxNumber}</div>}
                                </div>
                            </div>

                            <div className={s.row}>
                                <div className={s.input_block}>
                            <div className={s.input_label}>
                                * Citizenship
                            </div>
                            <SelectWithSearch
                                id="citizenshipName"
                                name="citizenshipName"
                                isError={errors.citizenshipName}
                                items={countries}
                                touched={touched.citizenshipName}
                            />
                            {errors.citizenshipName && touched.citizenshipName &&
                                <div className={s.error_message}>{errors.citizenshipName}</div>}
                                </div>
                                <div className={s.input_block}>
                                </div>
                            </div>

                            <div className={s.row}>
                                <div className={classnames(s.input_block)}>
                                    <div className={s.input_label}>
                                        * Sources of wealth or income
                                    </div>
                                    <div role="group" aria-labelledby="checkbox-group"
                                         className={s.sources_of_wealth_wrapper}>
                                        <label className={s.checkbox_block}>
                                            <Field
                                                render={(props: any) => {
                                                    return (
                                                        <Checkbox
                                                            checked={props.meta.value.sourcesOfWealth.includes("Inheritances")}
                                                            onChange={(e) => {
                                                                sourcesOfWealthHandlerChange(e, props.meta, props.form, "Inheritances")
                                                            }}
                                                        />
                                                    );
                                                }}
                                            />
                                            <span className={s.checkbox_label}>Inheritances</span>
                                        </label>
                                        <label className={s.checkbox_block}>
                                            <Field
                                                render={(props: any) => {
                                                    return (
                                                        <Checkbox
                                                            checked={props.meta.value.sourcesOfWealth.includes("Investments")}
                                                            onChange={(e) => {
                                                                sourcesOfWealthHandlerChange(e, props.meta, props.form, "Investments")
                                                            }}
                                                        />
                                                    );
                                                }}
                                            />
                                            <span className={s.checkbox_label}>Investments</span>
                                        </label>
                                        <label className={s.checkbox_block}>
                                            <Field
                                                render={(props: any) => {
                                                    return (
                                                        <Checkbox
                                                            checked={props.meta.value.sourcesOfWealth.includes("Business ownership interests")}
                                                            onChange={(e) => {
                                                                sourcesOfWealthHandlerChange(e, props.meta, props.form, "Business ownership interests")
                                                            }}
                                                        />
                                                    );
                                                }}
                                            />
                                            <span className={s.checkbox_label}>Business ownership interests</span>
                                        </label>
                                        <label className={s.checkbox_block}>
                                            <Field
                                                render={(props: any) => {
                                                    return (
                                                        <Checkbox
                                                            checked={props.meta.value.sourcesOfWealth.includes("Employment income")}
                                                            onChange={(e) => {
                                                                sourcesOfWealthHandlerChange(e, props.meta, props.form, "Employment income")
                                                            }}
                                                        />
                                                    );
                                                }}
                                            />
                                            <span className={s.checkbox_label}>Employment income</span>
                                        </label>
                                        <label className={s.checkbox_block}>
                                            <Field
                                                render={(props: any) => {
                                                    return (
                                                        <Checkbox
                                                            checked={props.meta.value.sourcesOfWealth.includes("Personal savings")}
                                                            onChange={(e) => {
                                                                sourcesOfWealthHandlerChange(e, props.meta, props.form, "Personal savings")
                                                            }}
                                                        />
                                                    );
                                                }}
                                            />
                                            <span className={s.checkbox_label}>Personal savings</span>
                                        </label>
                                        <label className={s.checkbox_block}>
                                            <Field
                                                render={(props: any) => {
                                                    return (
                                                        <Checkbox
                                                            checked={props.meta.value.sourcesOfWealth.includes("Pension releases")}
                                                            onChange={(e) => {
                                                                sourcesOfWealthHandlerChange(e, props.meta, props.form, "Pension releases")
                                                            }}
                                                        />
                                                    );
                                                }}
                                            />
                                            <span className={s.checkbox_label}>Pension releases</span>
                                        </label>
                                        <label className={s.checkbox_block}>
                                            <Field
                                                render={(props: any) => {
                                                    return (
                                                        <Checkbox
                                                            checked={props.meta.value.sourcesOfWealth.includes("Share sales and dividends")}
                                                            onChange={(e) => {
                                                                sourcesOfWealthHandlerChange(e, props.meta, props.form, "Share sales and dividends")
                                                            }}
                                                        />
                                                    );
                                                }}
                                            />
                                            <span className={s.checkbox_label}>Share sales and dividends</span>
                                        </label>
                                        <label className={s.checkbox_block}>
                                            <Field
                                                render={(props: any) => {
                                                    return (
                                                        <Checkbox
                                                            checked={props.meta.value.sourcesOfWealth.includes("Property sales")}
                                                            onChange={(e) => {
                                                                sourcesOfWealthHandlerChange(e, props.meta, props.form, "Property sales")
                                                            }}
                                                        />
                                                    );
                                                }}
                                            />
                                            <span className={s.checkbox_label}>Property sales</span>
                                        </label>
                                        <label className={s.checkbox_block}>
                                            <Field
                                                render={(props: any) => {
                                                    return (
                                                        <Checkbox
                                                            checked={props.meta.value.sourcesOfWealth.includes("Gambling winnings")}
                                                            onChange={(e) => {
                                                                sourcesOfWealthHandlerChange(e, props.meta, props.form, "Gambling winnings")
                                                            }}
                                                        />
                                                    );
                                                }}
                                            />
                                            <span className={s.checkbox_label}>Gambling winnings</span>
                                        </label>
                                        <label className={s.checkbox_block}>
                                            <Field
                                                render={(props: any) => {
                                                    return (
                                                        <Checkbox
                                                            checked={props.meta.value.sourcesOfWealth.includes("Inheritances and gifts")}
                                                            onChange={(e) => {
                                                                sourcesOfWealthHandlerChange(e, props.meta, props.form, "Inheritances and gifts")
                                                            }}
                                                        />
                                                    );
                                                }}
                                            />
                                            <span className={s.checkbox_label}>Inheritances and gifts</span>
                                        </label>
                                        <label className={s.checkbox_block}>
                                            <Field
                                                render={(props: any) => {
                                                    return (
                                                        <Checkbox
                                                            checked={props.meta.value.sourcesOfWealth.includes("Compensation from legal rulings")}
                                                            onChange={(e) => {
                                                                sourcesOfWealthHandlerChange(e, props.meta, props.form, "Compensation from legal rulings")
                                                            }}
                                                        />
                                                    );
                                                }}
                                            />
                                            <span className={s.checkbox_label}>Compensation from legal rulings</span>
                                        </label>
                                        <label className={s.checkbox_block}>
                                            <Field
                                                render={(props: any) => {
                                                    return (
                                                        <Checkbox
                                                            checked={props.meta.value.isOtherSourcesOfWealth}
                                                            onChange={(e) => {
                                                                const {checked} = e.target;
                                                                if (checked) {
                                                                    props.form.setFieldValue("sourcesOfWealth", []);
                                                                }
                                                                props.form.setFieldValue(
                                                                    "isOtherSourcesOfWealth",
                                                                    checked
                                                                );
                                                            }}
                                                        />
                                                    );
                                                }}
                                            />
                                            <span className={s.checkbox_label}>Other</span>
                                        </label>
                                    </div>
                                    {errors.sourcesOfWealth && values.sourcesOfWealth.length === 0 &&
                                        <div className={s.error_message}>{errors.sourcesOfWealth}</div>}
                                </div>
                            </div>

                            <div className={s.row}>
                                {
                                    // @ts-ignore
                                    values.isOtherSourcesOfWealth &&
                                    <div className={s.input_block}>
                                        <div className={s.input_label}>
                                            * Other
                                        </div>
                                        <MyInput id='otherSourcesOfWealth'
                                                 name='otherSourcesOfWealth'
                                                 onChange={handleChange}
                                                 value={values.otherSourcesOfWealth}
                                                 isError={errors.otherSourcesOfWealth}
                                                 touched={touched.otherSourcesOfWealth}
                                        />
                                        {errors.otherSourcesOfWealth &&
                                            <div className={s.error_message}>{errors.otherSourcesOfWealth}</div>}
                                    </div>
                                }
                                <div className={s.input_block}>
                                </div>
                            </div>

                            <div className={s.row}>
                                <div className={classnames(s.input_block)}>
                                    <div className={classnames(s.input_label, errors.currencies && s.doc_error)}
                                         id="checkbox-group">
                                        * Add a new account
                                    </div>
                                    <div role="group" aria-labelledby="checkbox-group" className={s.checkbox_wrapper}>
                                        <label className={s.checkbox_block}>
                                            <Field as={Checkbox} type="checkbox" name="currencies" value="CAD"/>
                                            <span className={s.checkbox_label}>CAD</span>
                                        </label>
                                        <label className={s.checkbox_block}>
                                            <Field as={Checkbox} type="checkbox" name="currencies" value="EUR"/>
                                            <span className={s.checkbox_label}>EUR</span>
                                        </label>
                                        <label className={s.checkbox_block}>
                                            <Field as={Checkbox} type="checkbox" name="currencies" value="GBP"/>
                                            <span className={s.checkbox_label}>GBP</span>
                                        </label>
                                        <label className={s.checkbox_block}>
                                            <Field as={Checkbox} type="checkbox" name="currencies" value="USD"/>
                                            <span className={s.checkbox_label}>USD</span>
                                        </label>
                                        <label className={s.checkbox_block}>
                                            <Field as={Checkbox} type="checkbox" name="currencies" value="USDT"/>
                                            <span className={s.checkbox_label}>USDT</span>
                                        </label>
                                        <label className={s.checkbox_block}>
                                            <Field as={Checkbox} type="checkbox" name="currencies" value="SGD"/>
                                            <span className={s.checkbox_label}>SGD</span>
                                        </label>
                                        <label className={s.checkbox_block}>
                                            <Field as={Checkbox} type="checkbox" name="currencies" value="HKD"/>
                                            <span className={s.checkbox_label}>HKD</span>
                                        </label>
                                        <label className={s.checkbox_block}>
                                            <Field as={Checkbox} type="checkbox" name="currencies" value="JPY"/>
                                            <span className={s.checkbox_label}>JPY</span>
                                        </label>
                                        <label className={s.checkbox_block}>
                                            <Field as={Checkbox} type="checkbox" name="currencies" value="CNH"/>
                                            <span className={s.checkbox_label}>CNH</span>
                                        </label>
                                        <label className={s.checkbox_block}>
                                            <Field as={Checkbox} type="checkbox" name="currencies" value="AED"/>
                                            <span className={s.checkbox_label}>AED</span>
                                        </label>
                                    </div>
                                    {errors.currencies &&
                                        <div className={s.error_message}>{errors.currencies}</div>}
                                </div>
                            </div>

                            <div className={s.row}>
                                <div className={s.input_block}>
                                    <div className={s.input_label}>
                                        * Politically exposed person (pep)
                                    </div>
                                    <div className={classnames(s.flex_label, s.radio_block)}>
                                        <label className={s.flex_label}>
                                            <CustomCheckbox
                                                handleChange={() => setIsPep(false)}
                                                id="No"
                                                name="No"
                                                isChecked={!isPep}
                                            />
                                            <span className={s.type_of_account}>No</span>
                                        </label>
                                        <label className={s.flex_label}>
                                            <CustomCheckbox
                                                handleChange={() => setIsPep(true)}
                                                id="Yes"
                                                name="Yes"
                                                isChecked={isPep}
                                            />
                                            <span className={s.type_of_account}>Yes</span>
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div className={s.line}></div>

                            <div className={s.add_doc_block}>
                                <div className={s.content_block}>
                                    <div>
                                        <img src={passportSpread} alt=""/>
                                    </div>
                                    <div>
                                        <div
                                            className={classnames(s.title, docsError && documents.passportSpread === '' && s.doc_error)}>
                                            <span className={s.required}>* </span>
                                            Passport spread with your photo
                                        </div>
                                        <div>
                                            <ul className={s.passport_spread_list}>
                                                <li>
                                        <span className={s.text_color}>
                                            A color photo contains full document in clear focus and the data can be read
                                            easily.
                                        </span>
                                                </li>
                                                <li>
                                        <span className={s.text_color}>
                                             There're no light glares impeding document readability.
                                        </span>
                                                </li>
                                                <li>
                                        <span className={s.text_color}>
                                            A photo was not edited in graphic applications (we will definitely check).
                                        </span>
                                                </li>
                                                <li>
                                        <span className={s.text_color}>
                                           Picture format: JPEG, JPG, PNG, PDF.
                                        </span>
                                                </li>
                                                <li>
                                        <span className={s.text_color}>
                                            Maximum file size: 8Mb.
                                        </span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className={s.attached_file_wrapper}>
                                    <div className={s.btn_block}>
                                        <TransparentButton
                                            icon={<Icon component={() => <Plus/>} rev={undefined}/>}
                                            title={"Add file"}
                                            onClick={(e: any) => {
                                                e.preventDefault()
                                                // @ts-ignore
                                                document.getElementById('hiddenPassportInput').click()
                                            }}
                                        />
                                        <input type="file"
                                               accept=".png, .jpg, .jpeg, .pdf"
                                               id={"hiddenPassportInput"}
                                               className={s.hidden_input}
                                               onChange={(event) => {
                                                   // @ts-ignore
                                                   setDocuments({
                                                       ...documents,
                                                       // @ts-ignore
                                                       passportSpread: event.currentTarget.files[0]
                                                   });
                                               }}
                                        />
                                    </div>
                                    <div className={s.attached_file}>
                                        {
                                            documents?.passportSpread?.name && <img src={attachedDoc} alt=""/>
                                        }
                                        {documents?.passportSpread?.name}
                                        {
                                            documents?.passportSpread?.name &&
                                            <img onClick={() => {
                                                // @ts-ignore
                                                document.getElementById("hiddenPassportInput").value = ""
                                                setDocuments({...documents, passportSpread: ""})
                                            }}
                                                 className={s.delete_doc}
                                                 src={delete_document}
                                                 alt=""
                                            />
                                        }
                                    </div>
                                </div>

                            </div>

                            <div className={s.line}></div>

                            <div className={s.add_doc_block}>
                                <div className={s.content_block}>
                                    <div>
                                        <img src={selfi} alt=""/>
                                    </div>
                                    <div>
                                        <div
                                            className={classnames(s.title, docsError && documents.selfie === '' && s.doc_error)}>
                                            <span className={s.required}>* </span>
                                            Selfie with your Passport
                                        </div>
                                        <div className={s.add_block_text}>
                                            Upload a selfie of the Account Holder holding his Passport (at the page with
                                            a
                                            photo) in
                                            one hand and a paper with written name of platform "{brandConfig.companyNameSimple}", date
                                            (dd/mm/yyyy/) and
                                            Account Holder's signature in the other hand. Photo should be good enough so
                                            it
                                            is
                                            possible to read ID data and see written on the paper text. You can use help
                                            of
                                            another
                                            person to make this photo or use a delayed photo function on your phone.
                                        </div>
                                    </div>
                                </div>
                                <div className={s.attached_file_wrapper}>
                                    <div className={s.btn_block}>
                                        <TransparentButton
                                            icon={<Icon component={() => <Plus/>} rev={undefined}/>}
                                            title={"Add file"}
                                            onClick={(e: any) => {
                                                e.preventDefault()
                                                // @ts-ignore
                                                document.getElementById('hiddenSelfieInput').click()
                                            }}
                                        />
                                        <input type="file"
                                               accept="image/png, image/jpeg, .pdf"
                                               id={"hiddenSelfieInput"}
                                               className={s.hidden_input}
                                               onChange={(event) => {
                                                   // @ts-ignore
                                                   setDocuments({...documents, selfie: event.currentTarget.files[0]})
                                               }}
                                        />
                                    </div>
                                    <div className={s.attached_file}>
                                        {
                                            documents?.selfie?.name && <img src={attachedDoc} alt=""/>
                                        }
                                        {documents?.selfie?.name}
                                        {
                                            documents?.selfie?.name &&
                                            <img onClick={() => {
                                                // @ts-ignore
                                                document.getElementById("hiddenSelfieInput").value = ""
                                                setDocuments({...documents, selfie: ''})
                                            }}
                                                 className={s.delete_doc}
                                                 src={delete_document}
                                                 alt=""
                                            />
                                        }
                                    </div>
                                </div>

                            </div>

                            <div className={s.line}></div>

                            <div className={s.add_doc_block}>
                                <div className={s.content_block}>
                                    <div>
                                        <img src={proof} alt=""/>
                                    </div>
                                    <div>
                                        <div
                                            className={classnames(s.title, docsError && documents.proofOfAddress === '' && s.doc_error)}>
                                            <span className={s.required}>* </span>
                                            Personal Proof of Address
                                        </div>
                                        <div>
                                            <ul className={s.passport_spread_list}>
                                                <li>
                                                    <span className={s.text_color}>
                                                        Bank statement with the date of issue and the name of the person (the document
                                                        must be not older than 3 months);
                                                    </span>
                                                </li>
                                                <li>
                                                    <span className={s.text_color}>
                                                         Utility bill for gas, electricity, water, internet, etc. linked to the property
                                                        (the document must not be older than 3 months).
                                                    </span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className={s.attached_file_wrapper}>
                                    <div className={s.btn_block}>
                                        <TransparentButton
                                            icon={<Icon component={() => <Plus/>} rev={undefined}/>}
                                            title={"Add file"}
                                            onClick={(e: any) => {
                                                e.preventDefault()
                                                // @ts-ignore
                                                document.getElementById('hiddenProofOfAddressInput').click()
                                            }}
                                        />
                                        <input type="file"
                                               accept="image/png, image/jpeg, application/pdf"
                                               id={"hiddenProofOfAddressInput"}
                                               className={s.hidden_input}
                                               onChange={(event) => {
                                                   setDocuments({
                                                       ...documents,
                                                       // @ts-ignore
                                                       proofOfAddress: event.currentTarget.files[0]
                                                   })
                                               }}
                                        />
                                    </div>
                                    <div className={s.attached_file}>
                                        {
                                            documents?.proofOfAddress?.name && <img src={attachedDoc} alt=""/>
                                        }
                                        {documents?.proofOfAddress?.name}
                                        {
                                            documents?.proofOfAddress?.name &&
                                            <img onClick={() => {
                                                // @ts-ignore
                                                document.getElementById("hiddenProofOfAddressInput").value = ""
                                                setDocuments({...documents, proofOfAddress: ''})
                                            }}
                                                 className={s.delete_doc}
                                                 src={delete_document}
                                                 alt=""
                                            />
                                        }
                                    </div>
                                </div>

                            </div>

                            <div className={s.line}></div>

                            <div className={s.add_doc_block}>
                                <div className={s.content_block}>
                                    <div>
                                        <img src={idCard} alt=""/>
                                    </div>
                                    <div>
                                        <div
                                            className={s.title}>
                                            ID Card/Residence Permit Card
                                        </div>
                                        <div className={s.add_block_text}>
                                            If you have National ID Card or Residence Permit Card, please upload it.
                                        </div>
                                    </div>
                                </div>
                                <div className={s.attached_file_wrapper}>
                                    <div className={s.btn_block}>
                                        <TransparentButton
                                            icon={<Icon component={() => <Plus/>} rev={undefined}/>}
                                            title={"Add file"}
                                            onClick={(e: any) => {
                                                e.preventDefault()
                                                // @ts-ignore
                                                document.getElementById('hiddenIdCardInput').click()
                                            }}
                                        />
                                        <input type="file"
                                               accept="image/png, image/jpeg, .pdf"
                                               id={"hiddenIdCardInput"}
                                               className={s.hidden_input}
                                               onChange={(event) => {
                                                   // @ts-ignore
                                                   setDocuments({...documents, idCard: event.currentTarget.files[0]})
                                               }}
                                        />
                                    </div>
                                    <div className={s.attached_file}>
                                        {
                                            documents?.idCard?.name && <img src={attachedDoc} alt=""/>
                                        }
                                        {documents?.idCard?.name}
                                        {
                                            documents?.idCard?.name &&
                                            <img onClick={() => {
                                                // @ts-ignore
                                                document.getElementById("hiddenIdCardInput").value = ""
                                                setDocuments({...documents, idCard: ''})
                                            }}
                                                 className={s.delete_doc}
                                                 src={delete_document}
                                                 alt=""
                                            />
                                        }
                                    </div>
                                </div>

                            </div>

                            <div className={s.line}></div>

                            <div className={s.add_doc_block}>
                                <div className={s.content_block}>
                                    <div>
                                        <img src={idCard} alt=""/>
                                    </div>
                                    <div>
                                        <div
                                            className={s.title}>
                                            Back of ID Card/Residence Permit Card
                                        </div>
                                        <div className={s.add_block_text}>
                                            If you uploaded ID Card/Driving license, please upload the back side of it.
                                        </div>
                                    </div>
                                </div>
                                <div className={s.attached_file_wrapper}>
                                    <div className={s.btn_block}>
                                        <TransparentButton
                                            icon={<Icon component={() => <Plus/>} rev={undefined}/>}
                                            title={"Add file"}
                                            onClick={(e: any) => {
                                                e.preventDefault()
                                                // @ts-ignore
                                                document.getElementById('hiddenBackOfIdCardInput').click()
                                            }}
                                        />
                                        <input type="file"
                                               accept="image/png, image/jpeg, .pdf"
                                               id={"hiddenBackOfIdCardInput"}
                                               className={s.hidden_input}
                                               onChange={(event) => {
                                                   // @ts-ignore
                                                   setDocuments({...documents, backOfIdCard: event.currentTarget.files[0]})
                                               }}
                                        />
                                    </div>
                                    <div className={s.attached_file}>
                                        {
                                            documents?.backOfIdCard?.name && <img src={attachedDoc} alt=""/>
                                        }
                                        {documents?.backOfIdCard?.name}
                                        {
                                            documents?.backOfIdCard?.name &&
                                            <img onClick={() => {
                                                // @ts-ignore
                                                document.getElementById("hiddenBackOfIdCardInput").value = ""
                                                setDocuments({...documents, backOfIdCard: ''})
                                            }}
                                                 className={s.delete_doc}
                                                 src={delete_document}
                                                 alt=""
                                            />
                                        }
                                    </div>
                                </div>

                            </div>

                            <div className={s.line}></div>

                            <div className={s.btn_block}>
                                <div className={s.button_back_wrapper}>
                                    <TransparentButton title={"Back"}
                                                       large
                                                       style={{width: '100%'}}
                                                       onClick={() => props.setCurrent(0)}
                                                       icon={<Icon component={() => <Back/>} rev={undefined}/>}
                                    />
                                </div>
                                <div className={s.button_apply_wrapper}>
                                    <MyBtn title={'Apply'} type={"submit"}/>
                                </div>
                            </div>

                            <IdentityCardTypeHelper
                                country={values.countryName}
                                identityCardType={values.identityCardType}
                                name={"identityCardType"}
                            />

                        </Form>
                    )}
                </Formik>


            </div>
        </motion.div>
    )


};

export default KycPersonalStepTwo;
