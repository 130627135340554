import React, { Dispatch, SetStateAction } from "react";
import { Popover } from "antd";
import info from "../../../assets/newImg/personalAccount/information.svg";
import CustomDropdown from "../../ui/CustomDropdown/CustomDropdown";
import CustomDropdownForAccounts from "../../ui/CustomDropdownForAccounts/CustomDropdownForAccounts";
import CustomModal from "../../ui/CustomModal/CustomModal";
import MyBtn from "../../ui/MyBtn/MyBtn";
import { useNavigate } from "react-router-dom";

type PropsType = {
    s: any;
    id: any;
    paymentRegions: any;
    errors: any;
    touched: any;
    setPaymentRegion: Dispatch<SetStateAction<any>>;
    accountsList: any;
    setCurrency: Dispatch<SetStateAction<any>>;
    generateType: string;
    isShowSuccessModal?: any;
    setIsShowSuccessModal?: Dispatch<SetStateAction<any>> | undefined;
};

const FormHeader = (props: PropsType) => {
    const {
        s,
        id,
        paymentRegions,
        errors,
        touched,
        setPaymentRegion,
        accountsList,
        setCurrency,
        generateType,
        isShowSuccessModal,
        setIsShowSuccessModal
    } = props;

    const navigate = useNavigate();

    const closeAndRedirect = () => {
        setIsShowSuccessModal && setIsShowSuccessModal(false);
        navigate(-1);
    };

    // const contentStyle = {
    //     fontFamily: "Inter",
    //     fontWeight: 500,
    //     fontSize: "14px",
    //     lineHeight: "18px",
    //     color: "#979797",
    // };

    // const menuStyle = {
    //     border: "1.5px solid #EEEFEF",
    //     borderRadius: 8,
    // };

    return (
        <>
            {id === "create" &&
                generateType === "transactionTemplate" && (
                    <>
                        <CustomModal
                            setIsModalOpen={setIsShowSuccessModal}
                            isModalOpen={isShowSuccessModal}
                        >
                            <div>
                                <div className={s.success_title}>Success!</div>
                                <span className={s.success_subtitle}>
                                    Your template was successfully created.
                                </span>
                                <div className={s.success_button_wrapper}>
                                    <div className={s.btn_block}>
                                        <MyBtn
                                            title={"Ok"}
                                            onClick={() => closeAndRedirect()}
                                        />
                                    </div>
                                </div>
                            </div>
                        </CustomModal>
                    </>
                )}
            <div className={s.row}>
                <div className={s.input_block}>
                    <div className={s.input_label}>
                        * Payment region
                        <Popover
                            content={
                                "Please select the account you wish to make a transfer from."
                            }
                            color={"#EEEFEF"}
                            overlayInnerStyle={{
                                fontFamily: "Inter",
                                width: "280px",
                                padding: "13px 16px",
                            }}
                        >
                            <img src={info} alt="" />
                        </Popover>
                    </div>
                    <CustomDropdown
                        customId="paymentRegions"
                        items={paymentRegions}
                        placeholder={"Please select the payment region"}
                        id="paymentRegions"
                        name="paymentRegions"
                        isError={errors.paymentRegions}
                        touched={touched.paymentRegions}
                        setItem={setPaymentRegion}
                    />
                    {errors.paymentRegions && touched.paymentRegions && (
                        <div className={s.error_message}>
                            {errors.paymentRegions}
                        </div>
                    )}
                </div>

                <div className={s.input_block}>
                    <div className={s.input_label}>
                        * Payer account
                        <Popover
                            content={
                                "Please select the account you wish to make a transfer from."
                            }
                            color={"#EEEFEF"}
                            overlayInnerStyle={{
                                fontFamily: "Inter",
                                width: "280px",
                                padding: "13px 16px",
                            }}
                        >
                            <img src={info} alt="" />
                        </Popover>
                    </div>
                    <CustomDropdownForAccounts
                        items={accountsList}
                        placeholder={"Please select the account"}
                        id="payerAccount"
                        name="payerAccount"
                        isError={errors.payerAccount}
                        touched={touched.payerAccount}
                        setCurrency={setCurrency}
                        disableZeroBalance={true}
                    />
                    {errors.payerAccount && touched.payerAccount && (
                        <div className={s.error_message}>
                            {errors.payerAccount}
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default FormHeader;
