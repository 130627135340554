import React, {useEffect} from 'react';
import s from './ExchangeRates.module.css'
import {motion} from "framer-motion";
import {Formik, useFormikContext} from "formik";
import MyInput from "../../../../components/ui/MyInput/MyInput";
import CustomDropdown from "../../../../components/ui/CustomDropdown/CustomDropdown";
import TransparentButton from "../../../../components/ui/TransparentButton/TransparentButton";
import {useAppDispatch, useAppSelector} from "../../../../hooks/redux";
import {
    setCurrencyExchangeAmountCheck,
    setCurrencyExchangeFromCheck, setCurrencyExchangeResultCheck,
    setCurrencyExchangeToCheck,setCurrencyExchangeToAmountCheck
} from "../../../../store/reducers/UserSlice";
import {
    sendCurrencyExchangeAmountCheck,
    sendCurrencyExchangeAmountReverseCheck
} from "../../../../store/reducers/ActionCreators";
import useDebounce from "../../../../hooks/useDebounce";
import * as yup from "yup";
import {brandConfig} from '../../../../config/config';

interface FormValues {
    sellCurrency: string;
    buyCurrency: string;
    sellAmount: string;
    buyAmount: string;
}

const SetCurrencyByAccount = (props: any) => {

    const { values } = useFormikContext<FormValues>();
    const dispatch = useAppDispatch()

    useEffect(() => {
        dispatch(setCurrencyExchangeFromCheck(values.sellCurrency))
    }, [dispatch, values.sellCurrency])

    useEffect(() => {
        dispatch(setCurrencyExchangeToCheck(values.buyCurrency))
    }, [dispatch, values.buyCurrency])

    useEffect(() => {
        if (!!values.sellAmount && values?.sellAmount?.length > 0) {
            dispatch(setCurrencyExchangeFromCheck(values.sellCurrency))
            dispatch(setCurrencyExchangeToCheck(values.buyCurrency))
            dispatch(setCurrencyExchangeAmountCheck(values.sellAmount))
        }

        if (!!values.buyAmount && values?.buyAmount?.length > 0) {
            dispatch(setCurrencyExchangeFromCheck(values.buyCurrency))
            dispatch(setCurrencyExchangeToCheck(values.sellCurrency))
            dispatch(setCurrencyExchangeToAmountCheck(values.buyAmount))
        }
    }, [dispatch, values.sellCurrency, values.buyCurrency, values.buyAmount, values.sellAmount])

    useEffect(() => {
        if (values.sellAmount === '') {
            dispatch(setCurrencyExchangeResultCheck(''))
            dispatch(setCurrencyExchangeAmountCheck(''))
        }
    }, [dispatch, values.sellAmount])

    useEffect(() => {
        if (values.buyAmount === '') {
            dispatch(setCurrencyExchangeResultCheck(''))
            dispatch(setCurrencyExchangeToAmountCheck(''))
        }
    }, [dispatch, values.buyAmount])

    return null;
};

const validationSchema = yup.object({
    sellAmount: yup.string()
        .matches(/^[-+]?[0-9]*[.]?[0-9]+(?:[eE][-+]?[0-9]+)?$/, "Must be only digits")
        .test({
            message: 'One of the fields is required',
            test: function (value) {
                const {buyAmount} = this.parent;
                if (!buyAmount) return value !== undefined && value !== null && value !== '';
                return true
            },
        }),
    buyAmount: yup.string()
        .matches(/^[-+]?[0-9]*[.]?[0-9]+(?:[eE][-+]?[0-9]+)?$/, "Must be only digits")
        .test({
            message: 'One of the fields is required',
            test: function (value) {
                const {sellAmount} = this.parent;
                if (!sellAmount) return value !== undefined && value !== null && value !== '';
                return true
            },
        }),
})

const ExchangeRates = () => {

    const currencyExchange = useAppSelector(state => state.userReducer.currencyExchangeCheck)
    const currencyExchangeResultCheck = useAppSelector(state => state.userReducer.currencyExchangeResultCheck)
    const dispatch = useAppDispatch()

    const debouncedValue = useDebounce(currencyExchange, 1500);

    useEffect(() => {
        const handleRequest = (debouncedValue: any) => {
            if (debouncedValue.amount !== '') {
                const tempData = {...debouncedValue}
                delete tempData.toAmount
                dispatch(sendCurrencyExchangeAmountCheck(tempData))
                return
            }
            if (debouncedValue.toAmount !== '') {
                const tempData = {...debouncedValue, amount: currencyExchange.toAmount}
                delete tempData.toAmount
                dispatch(sendCurrencyExchangeAmountReverseCheck(tempData))
                return
            }
        }
        handleRequest(debouncedValue)
    }, [dispatch, debouncedValue, currencyExchange.toAmount])

    const currencyList = [
        'USD',
        'EUR',
        'CAD',
        'GBP',
        'USDT',
        'SGD',
        'HKD',
        'JPY',
        'CNH',
        'AED',
    ]

    // const currencyData = [
    //     {
    //         officialRate: '0.219954',
    //         Iberbanco: '0.22',
    //         RBC: '0.22',
    //         Scotiaban: '0.22',
    //         TDBank: '0.22',
    //         currency: 'USD'
    //     },
    //     {
    //         officialRate: '0.482',
    //         Iberbanco: '0.48',
    //         RBC: '0.48',
    //         Scotiaban: '0.48',
    //         TDBank: '0.48',
    //         currency: 'EUR'
    //     },
    //     {
    //         officialRate: '0.85468',
    //         Iberbanco: '0.85',
    //         RBC: '0.85',
    //         Scotiaban: '0.85',
    //         TDBank: '0.85',
    //         currency: 'DKK'
    //     },
    //     {
    //         officialRate: '0.219954',
    //         Iberbanco: '0.22',
    //         RBC: '0.22',
    //         Scotiaban: '0.22',
    //         TDBank: '0.22',
    //         currency: 'NOK'
    //     },
    //     {
    //         officialRate: '0.219954',
    //         Iberbanco: '0.22',
    //         RBC: '0.22',
    //         Scotiaban: '0.22',
    //         TDBank: '0.22',
    //         currency: 'PLN'
    //     },
    //     {
    //         officialRate: '0.219954',
    //         Iberbanco: '0.22',
    //         RBC: '0.22',
    //         Scotiaban: '0.22',
    //         TDBank: '0.22',
    //         currency: 'RUB'
    //     },
    // ]

    // const tableData = currencyData.map((item: any, index: number) => {
    //     return {
    //         key: item.index,
    //         officialRate: item.officialRate + ' ' + item.currency,
    //         Iberbanco: item.Iberbanco + ' ' + item.currency,
    //         RBC: item.RBC + ' ' + item.currency,
    //         Scotiaban: item.Scotiaban + ' ' + item.currency,
    //         TDBank: item.TDBank + ' ' + item.currency,
    //     }
    // })

    return (
        <motion.div
            initial={{opacity: 0, scale: 1}}
            animate={{opacity: 1, scale: 1}}
            transition={{duration: 0.5}}
        >
            <div className={s.desc_block}>
                <p className={s.desc_text}>
                    {brandConfig.companyNameSimple} offer the same or even better currency exchange rates than those officially published on
                    the Internet by any commercial bank. This saves you money not only when performing a transfer, but
                    also when buying currency for a specific transfer. If the amount exchanged exceeds <b>5,000 EUR</b> we can
                    offer individual currency rates to private and business clients of {brandConfig.companyNameSimple} on request.
                </p>
                <p className={s.desc_text}>
                    Below in the currency calculator you can check the exchange rates offered by {brandConfig.companyNameSimple}.
                </p>
                <p>
                    <a className={s.link} href="https://www.bankofcanada.ca/">www.bankofcanada.ca</a>
                </p>
            </div>

            <div className={s.table_wrapper}>
                <Formik
                    initialValues={{
                        sellAmount: '',
                        sellCurrency: '',
                        buyAmount: '',
                        buyCurrency: '',
                    }}
                    validationSchema={validationSchema}
                    onSubmit={(values) => {
                    }}
                >
                    {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit
                      }) => (
                        <form className={s.form} onSubmit={handleSubmit}>
                            <div className={s.form_block}>
                                <div className={s.field_wrapper}>
                                    <div className={s.input_block}>
                                        <div className={s.input_label}>
                                            sell
                                        </div>
                                        <MyInput id='sellAmount'
                                                 name='sellAmount'
                                                 onChange={handleChange}
                                                 value={(currencyExchangeResultCheck && !values.sellAmount) ? currencyExchangeResultCheck : values.sellAmount}
                                                 disabled={!!values.buyAmount}
                                                 isError={errors.sellAmount}
                                        />
                                        {errors.sellAmount && touched.sellAmount &&
                                            <div className={s.error_message}>{errors.sellAmount}</div>}
                                    </div>
                                    <div className={s.sell_currency_block}>
                                        <CustomDropdown
                                            items={currencyList}
                                            id='sellCurrency'
                                            name='sellCurrency'
                                        />
                                    </div>
                                </div>
                                <div className={s.field_wrapper}>
                                    <div className={s.input_block}>
                                        <div className={s.input_label}>
                                            buy
                                        </div>
                                        <MyInput id='buyAmount'
                                                 name='buyAmount'
                                                 onChange={handleChange}
                                                 value={(currencyExchangeResultCheck && !values.buyAmount) ? currencyExchangeResultCheck : values.buyAmount}
                                                 disabled={!!values.sellAmount}
                                                 isError={errors.buyAmount}
                                        />
                                        {errors.buyAmount && touched.buyAmount &&
                                            <div className={s.error_message}>{errors.buyAmount}</div>}
                                    </div>
                                    <div className={s.sell_currency_block}>
                                        <CustomDropdown
                                            items={currencyList}
                                            id='buyCurrency'
                                            name='buyCurrency'
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className={s.converted_amount_block}>
                                <span>Amount of currency to sell: </span>
                                <b>{values.sellAmount ? values.sellAmount : currencyExchangeResultCheck} {values.sellCurrency}; </b>
                                <span>buying </span>
                                <b>{values.buyAmount ? values.buyAmount : currencyExchangeResultCheck} {values.buyCurrency}</b>
                            </div>
                            <SetCurrencyByAccount values={values}/>
                            <div className={s.button_wrapper}>
                                <TransparentButton title={'Update'} type={'submit'} large />
                            </div>
                        </form>
                    )}
                </Formik>

                {/*<Table*/}
                {/*    bordered={false}*/}
                {/*    rowClassName={(record, index) => index % 2 !== 0 ? `${s.table_row_dark}` :  ''}*/}
                {/*    className={s.statementTable}*/}
                {/*    columns={columns}*/}
                {/*    pagination={false}*/}
                {/*    // for info*/}
                {/*    expandable={{*/}
                {/*        expandIcon: ({expanded, onExpand, record}) =>*/}
                {/*            (<img src={arrowDown} className={expanded ? s.open : s.close}*/}
                {/*                  onClick={e => onExpand(record, e)}/>)*/}
                {/*        ,*/}
                {/*        expandedRowRender: (record) => <p style={{margin: 0}}>{record.description}</p>,*/}
                {/*        expandRowByClick: true,*/}

                {/*    }}*/}
                {/*    dataSource={tableData}*/}
                {/*/>*/}

            </div>

            <div className={s.official_rate}>
                * Official currency exchange rates are published on the Bank of Canada website
                    <a className={s.link} style={{marginLeft: '6px'}} href="https://www.bankofcanada.ca/" target="_blank" rel="noreferrer">
                        www.bankofcanada.ca
                    </a>
            </div>

        </motion.div>
    );
};

export default ExchangeRates;
