import React, {useEffect, useState} from 'react';
import KycPersonalStepOne from "./KycPersonalStepOne/KycPersonalStepOne";
import KycPersonalStepTwo from "./KycPersonalStepTwo/KycPersonalStepTwo";
import EditKycPersonal from "./EditKycPersonal/EditKycPersonal";
import {useAppDispatch, useAppSelector} from "../../../../../hooks/redux";
import {getPhoneListThunk} from "../../../../../store/reducers/ActionCreators";

const KycPersonal = () => {

    const [current, setCurrent] = useState(0);
    const token = useAppSelector(state => state.userReducer.user.userToken)
    const dispatch = useAppDispatch()

    useEffect(() => {
        dispatch(getPhoneListThunk(token))
    }, [dispatch, token])

    const steps = [
        {
            content: <KycPersonalStepOne setCurrent={setCurrent}
                                         current={current}
            />
        },
        {
            content: <KycPersonalStepTwo setCurrent={setCurrent}
                                         current={current}
            />
        },
        {
            content: <EditKycPersonal setCurrent={setCurrent}
                                      current={current}
            />
        },
    ];

    return (
        <div>{steps[current].content}</div>
    );
};

export default KycPersonal;
