import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import s from "./BankTransactionTemplate.module.css";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux";
import {
    createTemplate,
    getTemplateByIdThunk,
    SendTransferThunk,
} from "../../../store/reducers/ActionCreators";
import { PrepareDataForPaymentTemplate } from "../../../helpers/PrepareDataForPaymentTemplate";
import { ITemplate } from "../../../types/interfaces";
import { myDate } from "../../../helpers/CalculateDate";

import {
    otherBank,
    pixKeyTypesBrazil,
} from "../../../Constants/StaticData";
import {
    getBusinessPaymentType,
    getItemsPaymentType,
    getPhoneCode,
    getValidationSchema,
    PaymentTypeHelper,
    ValidationSchemaWithIgnore,
} from "../../BankTransferFormPersonal/FormHelpers/FormHelpers";

import {
    getAccountType,
    getBankCode,
    getBranchCode,
    getIdType,
    getProvider,
    getRecipientCountry,
    getTransferReason,
} from "./FormHelpers/FormHelpers";
import { PrepareDataForAzaTransaction } from "../../../helpers/PrepareDataForAzaTranzactions";
import { setDisabled } from "../../../store/reducers/UserSlice";
import { useSelector } from "react-redux";
import { selectAccountsByFilter } from "../../../store/reducers/TransactionsSlice/selector";
import { getValidationSchema as getBusinessValidationSchema } from "../../BankTransferFormBusiness/FormHelpers/FormHelpers";
import GenerateTransactionForm from "../../GenerateTransactionForm/GenerateTransactionForm";
import { brandConfig } from "../../../config/config";

type PropsType = {
    setCurrent?: Dispatch<SetStateAction<number>>;
    setTransferData?: Dispatch<SetStateAction<any>>;
    id?: string | number;
    templateName: string;
    setPurpose: Dispatch<SetStateAction<string>>;
};
const CurrencyHelper = (props: any) => {
    useEffect(() => {
        props.accountsList.forEach((account: any) => {
            if (account.number === props.accNumberFrom) {
                props.setCurrency(account.currency);
            }
        });
    }, [props.accountsList, props.accNumberFrom, props]);

    return null;
};

const BankTransactionTemplate = (props: PropsType) => {
    const { setCurrent, setTransferData, id, templateName } = props;

    const navigate = useNavigate();

    const [paymentType, setPaymentType] = useState("SHA");
    const [paymentBankTemplate, setPaymentBankTemplate] = useState<ITemplate>();
    const [date, setDate] = useState({
        from:
            myDate.getFullYear() +
            "-" +
            (myDate.getMonth() + 1 < 10
                ? ("0" + (myDate.getMonth() + 1)).slice(-2)
                : myDate.getMonth() + 1) +
            "-" +
            myDate.getDate(),
    });
    const [isShowSuccessModal, setIsShowSuccessModal] = useState(false);

    const [code, setCode] = useState({
        co: "ad",
        ph: "376",
        na: "Andorra",
    });


    const accountsList = useSelector(selectAccountsByFilter);
    const token = useAppSelector((state) => state.userReducer.user.userToken);
    const dispatch = useAppDispatch();
    const [currency, setCurrency] = useState("");
    const [selectedOptionRecipientType, setSelectedOptionRecipientType] =
        useState("");

    useEffect(() => {
        if (id !== "create") {
            dispatch(getTemplateByIdThunk(token, id)).then((res) => {
                if (res.data.type === "Intra Transaction") {
                    props.setPurpose(`Transfer to ${brandConfig.companyNameSimple} User`);
                } else {
                    setPaymentBankTemplate(res.data);
                    setSelectedOptionRecipientType(
                        res.data?.recipientDetails.recipientType ?? "INDIVIDUAL"
                    );
                }
            });
        }
    }, [dispatch, id, props, token]);

    // Recipient's Type
    const [paymentRegion, setPaymentRegion] = useState("");

    const selectedBankCode = getBankCode(
        paymentBankTemplate?.recipientDetails.paymentMethod,
        paymentBankTemplate?.recipientDetails.bankCode
    );

    const selectedBranchCode = getBranchCode(
        paymentBankTemplate?.recipientDetails.paymentMethod,
        paymentBankTemplate?.recipientDetails.branchCode
    );

    const selectedAccountType = getAccountType(
        paymentBankTemplate?.recipientDetails.paymentMethod,
        paymentBankTemplate?.recipientDetails.bankAccountType
    );

    const selectedRecipientCountry = getRecipientCountry(
        paymentBankTemplate?.recipientDetails.paymentMethod,
        paymentBankTemplate?.recipientDetails.country
    );

    const selectedMobileProvider = getProvider(
        paymentBankTemplate?.recipientDetails.paymentMethod,
        paymentBankTemplate?.recipientDetails.mobileProvider,
        selectedRecipientCountry?.name
    );

    const selectedTransferReason = getTransferReason(
        paymentBankTemplate?.recipientDetails.transferReason
    );

    const selectedIdType = getIdType(
        paymentBankTemplate?.recipientDetails.paymentMethod,
        paymentBankTemplate?.recipientDetails.identityCardType
    );

    const selectedPIX =
        pixKeyTypesBrazil.find(
            (bankCode) =>
                bankCode.value ===
                paymentBankTemplate?.recipientDetails.pixKeyType
        ) || otherBank;

    //to show phone number without code
    const mobCountry =
        paymentBankTemplate?.recipientDetails.recipientsBankCountry ===
        "WAEMU Region/XOF"
            ? selectedRecipientCountry?.name
            : paymentBankTemplate?.recipientDetails.recipientsBankCountry;
    const plainPhoneNumber = (
        paymentBankTemplate?.recipientDetails.phoneNumber || ""
    ).replace(`+${getPhoneCode(mobCountry || "", null).ph}`, "");

    const defaultInitials = {
        payerAccount: "",
        paymentRegions: "",
        paymentType: "",
        paymentTypeByRegionForm: "",
        bankCode: {
            name: "",
            value: "",
        },
        bankAccount: "",
        bankAccountType: {
            name: "",
            value: "",
        },
        fullName: "",
        firstName: "",
        lastName: "",
        middleName: "",
        recipientsAddress: "",
        beneficiaryState: "",
        recipientsCity: "",
        recipientsZipCode: "",
        recipientsCountry: "",
        recipientsAccountNumber: "",
        recipientsSwiftBic: "",
        recipientsIban: "",
        recipientsBankCountry: "",
        recipientsBankCity: "",
        recipientsBankState: "",
        recipientsBankAddress: "",
        recipientsBankZipCode: "",
        recipientsBank: "",
        email: "",
        emailLanguage: "EN",
        paymentDate: date.from,
        transferCurrency: "USD",
        purpose: "",
        reference: "",
        templateName: "",
        amount: "",
        type: "2",
        phoneNumber: "",
        recipientCountry: {
            name: "",
            value: "",
        },
        recipientCity: "",
        mobileProvider: {
            name: "",
            value: "",
        },
        street: "",
        city: "",
        postalCode: "",
        identityCardId: "",
        identityCardType: {
            name: "",
            value: "",
        },
        transferReason: {
            name: "",
            value: "",
        },
        routingNumber: "",
        cashProvider: "",
        branchCode: {
            name: "",
            value: "",
        },
        branchCodeTED: "",
        pixKeyType: {
            name: "",
            value: "",
        },
        pixKeyValue: "",
        relationshipToSender: "",
        institutionNumber: "",
        transitNumber: "",
        uniqueId: "",
        sortCode: "",
        senderIdType: {
            name: "",
            value: "",
        },
        senderIdNumber: "",
        beneficiaryName: "",
        beneficiaryEmail: "",
        securityQuestion: "",
        securityAnswer: "",
        payeeName: "",
        payeeCode: "",
        payeeAccountNumber: "",
        invoice: "",
        name: "", // for business
        natureOfBusiness: {
            name: "",
            value: "",
        }, // for business
        registrationNumber: "", // for business
        legalEntityType: {
            name: "",
            value: "",
        }, // for business
        recipientType: selectedOptionRecipientType,
    };
    
    const myInitValues = {
        payerAccount: paymentBankTemplate?.account,
        paymentRegions: paymentBankTemplate?.paymentRegion,
        fullName: paymentBankTemplate?.recipientDetails?.fullName,
        firstName: paymentBankTemplate?.recipientDetails?.firstName,
        lastName: paymentBankTemplate?.recipientDetails?.lastName,
        middleName: paymentBankTemplate?.recipientDetails?.middleName,
        paymentTypeByRegionForm:
            paymentBankTemplate?.recipientDetails.paymentMethod,
        recipientsAddress:
            paymentBankTemplate?.recipientDetails.recipientsAddress,
        recipientsAccountNumber:
            paymentBankTemplate?.recipientDetails.recipientsAccountNumber,
        recipientsSwiftBic:
            paymentBankTemplate?.recipientDetails.recipientsSwiftBic,
        recipientsCountry:
            paymentBankTemplate?.recipientDetails.recipientsCountry,
        beneficiaryState:
            paymentBankTemplate?.recipientDetails.beneficiaryState,
        recipientsCity: paymentBankTemplate?.recipientDetails.recipientsCity,
        recipientsZipCode:
            paymentBankTemplate?.recipientDetails.recipientsZipCode,
        recipientsBankZipCode:
            paymentBankTemplate?.recipientDetails.recipientsBankZipCode,
        recipientsBankCountry:
            paymentBankTemplate?.recipientDetails.recipientsBankCountry ?? "",
        recipientsBankAddress:
            paymentBankTemplate?.recipientDetails.recipientsBankAddress,
        recipientsBank: paymentBankTemplate?.recipientDetails.recipientsBank,
        recipientsIban: paymentBankTemplate?.recipientDetails.recipientsIban,
        bankCode: {
            name: selectedBankCode?.name,
            value: selectedBankCode?.value,
        },
        bankAccount: paymentBankTemplate?.recipientDetails?.bankAccount,
        bankAccountType: {
            name: selectedAccountType?.name,
            value: selectedAccountType?.value,
        },
        email: paymentBankTemplate?.email,
        emailLanguage: "EN",
        transferCurrency: "USD",
        purpose: "",
        reference: "",
        type: "2",
        paymentDate: date.from,
        amount: "",
        phoneNumber: plainPhoneNumber,
        recipientCountry: {
            name: selectedRecipientCountry?.name,
            value: selectedRecipientCountry?.value,
        },
        mobileProvider: {
            name: selectedMobileProvider?.name,
            value: selectedMobileProvider?.value,
        },
        street: paymentBankTemplate?.recipientDetails?.street,
        city: paymentBankTemplate?.recipientDetails?.city,
        postalCode: paymentBankTemplate?.recipientDetails?.postalCode,
        identityCardId: paymentBankTemplate?.recipientDetails?.identityCardId,
        identityCardType: {
            name: selectedIdType?.name,
            value: selectedIdType?.value,
        },
        transferReason: {
            name: selectedTransferReason?.name,
            value: selectedTransferReason?.value,
        },
        routingNumber: paymentBankTemplate?.recipientDetails?.routingNumber,
        cashProvider: paymentBankTemplate?.recipientDetails?.cashProvider,
        branchCode: {
            name: selectedBranchCode?.name,
            value: selectedBranchCode?.value,
        },
        branchCodeTED: paymentBankTemplate?.recipientDetails?.branchCode,
        pixKeyType: {
            name: selectedPIX?.name,
            value: selectedPIX?.value,
        },
        pixKeyValue: paymentBankTemplate?.recipientDetails?.pixKeyValue,
        relationshipToSender:
            paymentBankTemplate?.recipientDetails?.relationshipToSender,

        senderIdType: {
            name: "",
            value: "",
        },
        senderIdNumber: "",

        name: paymentBankTemplate?.recipientDetails.name, // for business
        natureOfBusiness: {
            name: "",
            value: "",
        }, // for business
        registrationNumber: "", // for business
        legalEntityType: {
            name: "",
            value: "",
        }, // for business
        // transferReason: {
        //     name: '',
        //     value: ''
        // },
        recipientType:
            paymentBankTemplate?.recipientDetails?.recipientType ?? "BUSINESS",
    };

    return (
        <div className={s.form_wrapper}>
            <motion.div
                initial={{ opacity: 0, scale: 1 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.5 }}
            >
                <GenerateTransactionForm
                    s={s}
                    id={id}
                    accountsList={accountsList}
                    selectedOptionRecipientType={selectedOptionRecipientType}
                    findRelatedValidationSchema={() => {
                        if (id === "create") {
                            if (paymentRegion === "PAN AFRICA") {
                                if (
                                    selectedOptionRecipientType === "INDIVIDUAL"
                                ) {
                                    return ValidationSchemaWithIgnore(
                                        getValidationSchema(
                                            paymentBankTemplate
                                                ?.recipientDetails
                                                ?.paymentMethod ?? "",
                                            paymentRegion
                                        ),
                                        ["amount"]
                                    );
                                } else {
                                    return ValidationSchemaWithIgnore(
                                        getBusinessValidationSchema(
                                            paymentBankTemplate
                                                ?.recipientDetails
                                                ?.paymentMethod ?? "",
                                            paymentRegion
                                        ),
                                        ["amount"]
                                    );
                                }
                            } else {
                                return ValidationSchemaWithIgnore(
                                    getValidationSchema(
                                        paymentBankTemplate?.recipientDetails
                                            ?.paymentMethod ?? "",
                                        paymentRegion
                                    ),
                                    ["amount"]
                                );
                            }
                        } else {
                            if (paymentRegion === "PAN AFRICA") {
                                if (
                                    selectedOptionRecipientType === "INDIVIDUAL"
                                ) {
                                    return getValidationSchema(
                                        paymentBankTemplate?.recipientDetails
                                            ?.paymentMethod ?? "",
                                        paymentRegion
                                    );
                                } else {
                                    return getBusinessValidationSchema(
                                        paymentBankTemplate?.recipientDetails
                                            ?.paymentMethod ?? "",
                                        paymentRegion
                                    );
                                }
                            } else {
                                return getValidationSchema(
                                    paymentBankTemplate?.recipientDetails
                                        ?.paymentMethod ?? "",
                                    paymentRegion
                                );
                            }
                        }
                    }}
                    currency={currency}
                    paymentType={paymentType}
                    CurrencyHelper={CurrencyHelper}
                    PaymentTypeHelper={PaymentTypeHelper}
                    generateType="transactionTemplate"
                    initialValues={paymentBankTemplate ? myInitValues : defaultInitials}
                    setPaymentType={setPaymentType}
                    setCurrent={setCurrent}
                    setTransferData={setTransferData}
                    setPaymentRegion={setPaymentRegion}
                    setCurrency={setCurrency}
                    setSelectedOptionRecipientType={
                        setSelectedOptionRecipientType
                    }
                    getItemsPaymentType={getItemsPaymentType}
                    getBusinessPaymentType={getBusinessPaymentType}
                    setCode={setCode}
                    setPaymentBankTemplate={setPaymentBankTemplate}
                    date={date}
                    setDate={setDate}
                    isShowSuccessModal={isShowSuccessModal}
                    setIsShowSuccessModal={setIsShowSuccessModal}
                    onSubmit={async (values) => {
                        dispatch(setDisabled(true));
                        const tempData = {
                            ...values,
                            paymentType,
                            phoneNumber:
                                values.phoneNumber !== ""
                                    ? `+${code.ph}${values.phoneNumber}`
                                    : "",
                            templateName: templateName,
                            transferCurrency: currency,
                            paymentDate: date.from,
                        };

                        if (id === "create") {
                            const preparedData =
                                PrepareDataForPaymentTemplate(tempData);

                            dispatch(createTemplate(token, preparedData))
                                .then((res) => {
                                    if (res?.data === "Created") {
                                        setIsShowSuccessModal(true);
                                    }
                                })
                                .finally(() => {
                                    dispatch(setDisabled(false));
                                });
                        } else {
                            const secondTempData = {
                                ...tempData,
                                type: "international",
                            };
                            const preparedData =
                                PrepareDataForAzaTransaction(secondTempData);
                            dispatch(
                                SendTransferThunk(token, await preparedData)
                            )
                                .then((res) => {
                                    if (res?.data === "Created") {
                                        navigate(-1);
                                    } else {
                                        console.error(res.data);
                                    }
                                })
                                .finally(() => {
                                    dispatch(setDisabled(false));
                                });
                        }
                    }}
                />
            </motion.div>
        </div>
    );
};

export default BankTransactionTemplate;
