import React, {
    Dispatch,
    SetStateAction,
} from "react";
import { Formik } from "formik";
import FormHeader from "./FormHeader/FormHeader";
import { paymentRegions } from "../../Constants/StaticData";
import FormBody from "./FromBody/FormBody";
import FormBottom from "./FormBottom/FormBottom";

type typesOfGenerate =
    | "personalTransferForm"
    | "businessTransferForm"
    | "transactionTemplate";

type PropsType = {
    s: any;
    accountsList?: any;
    selectedOptionRecipientType?: any;
    currency?: any;
    paymentType?: any;
    setPaymentType: Dispatch<SetStateAction<any>>;
    setPaymentRegion: Dispatch<SetStateAction<any>>;
    setCurrency: Dispatch<SetStateAction<any>>;
    setSelectedOptionRecipientType: Dispatch<SetStateAction<any>>;
    getItemsPaymentType: (field: string) => string[];
    getBusinessPaymentType: (field: string) => string[];
    setPaymentTypeByRegion?: Dispatch<SetStateAction<any>>;
    setCode?: Dispatch<SetStateAction<any>>;
    findRelatedValidationSchema: any;
    setTransferData: Dispatch<SetStateAction<any>> | undefined;
    setCurrent: Dispatch<SetStateAction<any>> | undefined;
    id?: string | number;
    CurrencyHelper?: React.FC<any> | undefined;
    PaymentTypeHelper?: React.FC<any> | undefined;
    PayerAccountHelper?: React.FC<any> | undefined;
    generateType: typesOfGenerate;
    setPaymentBankTemplate?: Dispatch<SetStateAction<any>>;
    initialValues: any;
    setTemplateName?: Dispatch<SetStateAction<any>> | undefined;
    onSubmit: (values: any) => void;
    setDate: Dispatch<SetStateAction<any>>;
    date: any;
    isShowSuccessModal?: any;
    setIsShowSuccessModal?: Dispatch<SetStateAction<any>>;
};

const GenerateTransactionForm = (props: PropsType) => {
    const {
        s,
        id,
        accountsList,
        selectedOptionRecipientType,
        findRelatedValidationSchema,
        currency,
        paymentType,
        CurrencyHelper,
        PaymentTypeHelper,
        PayerAccountHelper,
        generateType,
        initialValues,
        date,
        isShowSuccessModal,
        setPaymentType,
        setPaymentRegion,
        setCurrency,
        setSelectedOptionRecipientType,
        getItemsPaymentType,
        getBusinessPaymentType,
        setPaymentTypeByRegion,
        setCode,
        onSubmit,
        setDate,
        setIsShowSuccessModal,
    } = props;

    return (
        <Formik
            enableReinitialize={generateType === "transactionTemplate" ? true : false}
            initialValues={initialValues}
            validationSchema={findRelatedValidationSchema}
            onSubmit={onSubmit}
        >
            {({
                values,
                errors,
                touched,
                setFieldValue,
                handleChange,
                handleSubmit,
            }) => (
                <form onSubmit={handleSubmit}>
                    <FormHeader
                        s={s}
                        id={id}
                        paymentRegions={paymentRegions}
                        errors={errors}
                        touched={touched}
                        setPaymentRegion={setPaymentRegion}
                        accountsList={accountsList}
                        setCurrency={setCurrency}
                        generateType={generateType}
                        isShowSuccessModal={isShowSuccessModal}
                        setIsShowSuccessModal={setIsShowSuccessModal}
                    />

                    <FormBody
                        s={s}
                        values={values}
                        setSelectedOptionRecipientType={
                            setSelectedOptionRecipientType
                        }
                        setFieldValue={setFieldValue}
                        selectedOptionRecipientType={
                            selectedOptionRecipientType
                        }
                        errors={errors}
                        touched={touched}
                        getItemsPaymentType={getItemsPaymentType}
                        getBusinessPaymentType={getBusinessPaymentType}
                        setPaymentTypeByRegion={setPaymentTypeByRegion}
                        handleChange={handleChange}
                        setCode={setCode}
                    />

                    <FormBottom
                        s={s}
                        id={id}
                        errors={errors}
                        touched={touched}
                        values={values}
                        currency={currency}
                        paymentType={paymentType}
                        date={date}
                        setDate={setDate}
                        setCurrency={setCurrency}
                        handleChange={handleChange}
                        setPaymentType={setPaymentType}
                        setFieldValue={setFieldValue}
                        CurrencyHelper={CurrencyHelper}
                        PaymentTypeHelper={PaymentTypeHelper}
                        PayerAccountHelper={PayerAccountHelper}
                        generateType={generateType}
                    />
                </form>
            )}
        </Formik>
    );
};

export default GenerateTransactionForm;
